// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-font-family: var(--font-family);
  /** primary **/
  --ion-color-primary: var(--color-primary);
  --ion-color-primary-rgb: var(--color-primary-rgb);
  --ion-color-primary-contrast: var(--color-primary-contrast);
  --ion-color-primary-contrast-rgb: var(--color-primary-contrast-rgb);
  --ion-color-primary-shade: var(--color-primary-tint);
  --ion-color-primary-tint: var(--color-primary-tint-rgb);

  /** secondary **/
  --ion-color-secondary: var(--color-secondary);
  --ion-color-secondary-rgb: var(--color-secondary-rgb);
  --ion-color-secondary-contrast: var(--color-secondary-contrast);
  --ion-color-secondary-contrast-rgb: var(--color-secondary-contrast-rgb);
  --ion-color-secondary-shade: var(--color-secondary-shade);
  --ion-color-secondary-tint: rgba(var(--ion-color-secondary-rgb), 0.8);

  /** tertiary **/
  --ion-color-tertiary: var(--color-primary);
  --ion-color-tertiary-rgb: var(--color-primary-rgb);
  --ion-color-tertiary-contrast: var(--color-primary-contrast);
  --ion-color-tertiary-contrast-rgb: var(--color-primary-contrast-rgb);
  --ion-color-tertiary-shade: var(--color-primary-shade);
  --ion-color-tertiary-tint: rgba(var(--ion-color-primary-rgb), 0.8);

  /** success **/
  --ion-color-success: var(--color-success);
  --ion-color-success-rgb: var(--color-success-rgb);
  --ion-color-success-contrast: var(--color-light);
  --ion-color-success-contrast-rgb: var(--color-light-rgb);
  --ion-color-success-shade: rgb(var(--color-success-rgb), 0.8);
  --ion-color-success-tint: rgb(var(--color-success-rgb), 0.9);

  /** warning **/
  --ion-color-warning: rgba(var(--ion-color-danger-rgb), 0.8);
  --ion-color-warning-rgb: var(--ion-color-danger-rgb);
  --ion-color-warning-contrast: var(--color-light);
  --ion-color-warning-contrast-rgb: var(--color-light-rgb);
  --ion-color-warning-shade: rgba(var(--ion-color-danger-rgb), 0.6);
  --ion-color-warning-tint: rgba(var(--ion-color-danger-rgb), 0.9);

  /** danger **/
  --ion-color-danger: var(--color-danger);
  --ion-color-danger-rgb: var(--color-danger-rgb);
  --ion-color-danger-contrast: var(--color-light);
  --ion-color-danger-contrast-rgb: var(--color-light-rgb);
  --ion-color-danger-shade: var(--color-danger-shade);
  --ion-color-danger-tint: var(--color-danger-tint);

  /** dark **/
  --ion-color-dark: var(--color-dark);
  --ion-color-dark-rgb: var(--color-dark-rgb);
  --ion-color-dark-contrast: var(--color-light);
  --ion-color-dark-contrast-rgb: var(--color-light-rgb);
  --ion-color-dark-shade: var(--color-dark-shade);
  --ion-color-dark-tint: var(--color-dark-disabled);

  /** medium **/
  --ion-color-medium: var(--color-text);
  --ion-color-medium-rgb: var(--color-text-rgb);
  --ion-color-medium-contrast: var(--color-light);
  --ion-color-medium-contrast-rgb: var(--color-light-rgb);
  --ion-color-medium-shade: rgb(var(--color-text-rgb), 0.8);
  --ion-color-medium-tint: rgb(var(--color-text-rgb), 0.9);

  /** light **/
  --ion-color-light: var(--color-light);
  --ion-color-light-rgb: var(--color-light-rgb);
  --ion-color-light-contrast: var(--color-dark);
  --ion-color-light-contrast-rgb: var(--color-dark-rgb);
  --ion-color-light-shade: var(--color-border);
  --ion-color-light-tint: var(--color-light);

  // ___________________
  // Set colors like a classNames
  // https://ionicframework.com/docs/theming/advanced#adding-colors
  .ion-color-primary {
    --ion-color-base: var(--color-primary);
    --ion-color-base-rgb: var(--color-primary-rgb);
    --ion-color-contrast: var(--color-primary-contrast);
    --ion-color-contrast-rgb: var(--color-primary-contrast-rgb);
    --ion-color-shade: var(--color-primary-shade);
    --ion-color-tint: var(--color--primary-tint);
  }

  .ion-color-text {
    --ion-color-base: var(--color-text);
    --ion-color-base-rgb: var(--color-text-rgb);
    --ion-color-contrast: var(--color-text-contrast);
    --ion-color-contrast-rgb: var(--color-text-contrast-rgb);
    --ion-color-shade: var(--color-text-shade);
    --ion-color-tint: var(--color--text-disable);
  }

  .ion-color-text-disable {
    --ion-color-base: var(--color-text-disable);
    --ion-color-base-rgb: var(--color-text-disable-rgb);
    --ion-color-contrast: var(--color-text-contrast);
    --ion-color-contrast-rgb: var(--color-text-contrast-rgb);
    --ion-color-shade: var(--color-text-shade);
    --ion-color-tint: var(--color--text-disable);
  }

  .ion-color-background {
    --ion-color-base: var(--color-background);
    --ion-color-base-rgb: var(--color-background-rgb);
    --ion-color-contrast: var(--color-background-dark-contrast);
    --ion-color-contrast-rgb: var(--color--backgroundcontrast-rgb);
    --ion-color-shade: var(--color-background-shade);
    --ion-color-tint: var(--color--background-tint);
  }

  .ion-color-background-dark-contrast {
    --ion-color-base: var(--color-background-dark-contrast);
    --ion-color-base-rgb: var(--color-background-dark-contrast-rgb);
    --ion-color-contrast: var(--color-background);
    --ion-color-contrast-rgb: var(--color-background-rgb);
    --ion-color-shade: var(--color-background-dark-contrast-shade);
    --ion-color-tint: var(--color--background-contrast-tint);
  }

  .ion-color-border {
    --ion-color-base: var(--color-border);
    --ion-color-base-rgb: var(--color-border-rgb);
    --ion-color-contrast: var(--ion-color-light);
    --ion-color-contrast-rgb: var(--ion-color-light-rgb);
    --ion-color-shade: var(--color-background-dark-contrast-shade);
    --ion-color-tint: var(--color--background-contrast-tint);
  }

  .ion-color-secondary {
    --ion-color-base: var(--color-secondary);
    --ion-color-base-rgb: var(--color-secondary-rgb);
    --ion-color-contrast: var(--color-secondary-contrast);
    --ion-color-contrast-rgb: var(--color-secondary-contrast-rgb);
    --ion-color-shade: var(--color-secondary-shade);
    --ion-color-tint: var(--color--secondary-tint);
  }

  $max-width-mobile: 980px;
  $max-width-tablet: 1366px;
  $max-width-desktop: 2000px;
  --max-width-mobile: #{$max-width-mobile};
  --max-width-tablet: #{$max-width-tablet};
  --max-width-desktop: #{$max-width-desktop};

  --logo-width: 12vw;
  --logo-width-mobile: 100px;
}
